

import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import ParityService, { ParityServiceS } from '@/modules/cars/modules/parity/parity.service';
import PerformanceGraph from '@/modules/cars/modules/parity/components/brokers/performance-graph.vue';

@Component({
    components: {
        PerformanceGraph,
    },
})
export default class PerformanceBrokers extends Vue {
    @inject(ParityServiceS) private parityService!: ParityService;
}
