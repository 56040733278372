

import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import moment from 'moment';
import ParityService, { ParityServiceS } from '@/modules/cars/modules/parity/parity.service';

@Component
export default class BrokersTableInfo extends Vue {
    @inject(ParityServiceS) private parityService!: ParityService;

    get date() {
        let latestBmlScanDate = '';
        const brokerItems = this.parityService.storeState.brokers.items;
        if (brokerItems && brokerItems.length) {
            latestBmlScanDate = moment(brokerItems[0].latestBmlScanDate).format('MMM D');
        }
        return latestBmlScanDate;
    }
}
