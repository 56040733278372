
import { inject } from '@/inversify';
import { Component, Vue } from 'vue-property-decorator';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import BmlInfoTooltip from '@/modules/cars/modules/parity/components/brokers/bml-info-tooltip.vue';
import DistributionHealthTooltip from '@/modules/cars/modules/parity/components/brokers/distribution-health-tooltip.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import ParitySettingsService, { ParitySettingsServiceS } from '../../parity-settings.service';
import ParityService, { ParityServiceS } from '../../parity.service';
import BmlTooltip from '../bml-tooltip.vue';

export interface ITableCellItem {
    [key: string]: string | number | object;
}

export interface ITableHeadItem {
    value: string;
    key: string;
    scale: number;
}

const columns: ITableHeadItem[] = [{
    key: 'brokerName',
    value: 'Broker',
    scale: 0.39,
}, {
    key: 'health',
    value: 'Distribution Health',
    scale: 0.6,
}, {
    key: 'bml',
    value: 'BML',
    scale: 0.67,
}, {
    key: 'severeIssues',
    value: 'Severe Issues',
    scale: 0.5,
}];
// }, {
//     key: 'allocation',
//     value: 'Allocation',
//     scale: 0.5,
// }];
@Component({
    components: {
        LoaderWrapper,
        BmlTooltip,
        DistributionHealthTooltip,
        BmlInfoTooltip,
    },
})
export default class BrokersTable extends Vue {
    @inject(ParityServiceS) private parityService!: ParityService;
    @inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;
    @inject(CarsFiltersServiceS) carsFilterService!: CarsFiltersService;

    private sortItems = 'DESC';

    private logos = [
        'expedia',
        'autoeurope',
        'rentalcars',
        'hertz',
        'alamo',
        'avis',
        'dollar',
        'budget',
        'europcar',
        'sixt',
        'thrifty',
        'virtuo',
    ];

    getImage(broker: string) {
        const { isBrokerMode } = this.carsFilterService;
        let name = broker;
        if (isBrokerMode) {
            const [, brand] = broker.split(',');
            name = brand;
        }
        const images = require.context('@/modules/common/assets/broker-logos', false, /\.png$|\.jpg$|\.svg$/);
        const found = this.logos.find(item => item === name.toLocaleLowerCase());
        if (!found) {
            return images('./placeholder.svg');
        }
        return images(`./${found}.svg`);
    }

    get columns(): ITableHeadItem[] {
        return columns;
    }

    get columnGrid() {
        return this.columns.map(column => `${column.scale}fr`).join(' ');
    }

    get items(): ITableCellItem[] | null {
        const { brokers } = this.parityService;

        if (!brokers || !brokers.length) {
            return null;
        }

        let items: ITableCellItem[] = brokers.map(document => ({
            ...document,
        }));
        items = this.sortItems === 'DESC'
            // @ts-ignore
            ? items.sort((a, b) => b.health - a.health)
            // @ts-ignore
            : items.sort((a, b) => a.health - b.health);
        return items;
    }

    sort() {
        this.sortItems = this.sortItems === 'ASC' ? 'DESC' : 'ASC';
    }

    setPriceDiff(event: MouseEvent) {
        const target = event.target as HTMLElement | null;

        if (target && target.id === 'severe') {
            this.paritySettingsService.priceDif = [[null, -0.2], [-0.2, -0.1]];
        } else {
            this.paritySettingsService.priceDif = null;
        }
    }

    getColor(bmlClassification: 'B' | 'M' | 'L') {
        switch (bmlClassification) {
            case 'B':
                return 'yellow';
            case 'M':
                return 'green';
            case 'L':
                return 'red';
            default:
                return 'grey';
        }
    }

    getBrandName(broker: string) {
        const { currentChainCode, isBrokerMode } = this.carsFilterService;
        if (currentChainCode && isBrokerMode) {
            const [, brand] = broker.split(',');
            return [currentChainCode, brand].join('_');
        }
        return broker;
    }
}
